import React, { type FC, useEffect, memo } from "@messenger/lib/teact/teact";
import { getActions, withGlobal } from "@messenger/global";

import type { GlobalState } from "@messenger/global/types";

import "@messenger/global/actions/initial";
import { pick } from "@messenger/util/iteratees";
import { PLATFORM_ENV } from "@messenger/util/environment";
import windowSize from "@messenger/util/windowSize";

import CreateAccount from "./CreateAccount";
import CompanyName from "./CompanyName";
import CompanyPhoto from "./CompanyPhoto";

import LoadingSpinner from "@messenger/components/ui/Loading";
import LanguageSelector from "@messenger/components/language-selector";
import CompanyCreated from "./CompanyCreated";

type OwnProps = {
  isActive: boolean;
};

type StateProps = Pick<GlobalState, "businessAuthState" | "connectionState">;

const BusinessAuth: FC<OwnProps & StateProps> = ({
  businessAuthState,
  connectionState,
}) => {
  const { initBusiness } = getActions();

  useEffect(() => {
    if (connectionState === "connectionStateReady") {
      initBusiness();
    }
  }, [initBusiness, connectionState]);

  const isMobile = PLATFORM_ENV === "iOS" || PLATFORM_ENV === "Android";

  // Prevent refresh when rotating device
  useEffect(() => {
    windowSize.disableRefresh();

    return () => {
      windowSize.enableRefresh();
    };
  }, []);

  const renderContent = () => {
    switch (businessAuthState) {
      case "businessAuthStateAccountInfo":
        return <CreateAccount />;
      case "businessAuthStateCompanyName":
        return <CompanyName />;
      case "businessAuthStateCompanyPhoto":
        return <CompanyPhoto />;
      case "businessAuthStateCompanyCreated":
        return <CompanyCreated />;
      default:
        return <Loading />;
    }
  };

  return (
    <div className="h-full w-full bg-[#37CB37] flex justify-center items-center relative">
      <div>
        <LanguageSelector />
      </div>
      <div className="w-4/5 h-4/5 bg-center bg-no-repeat bg-contain flex justify-center bg-[url('@messenger/assets/business/auth-bg.png')]">
        <div className="w-[400px] h-full bg-white rounded-2xl p-9 flex">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default memo(
  withGlobal<OwnProps>(
    (global): StateProps =>
      pick(global, ["businessAuthState", "connectionState"]),
  )(BusinessAuth),
);

const Loading = () => {
  return (
    <div className="flex w-full h-full justify-center items-center">
      <LoadingSpinner color="black" />
    </div>
  );
};
